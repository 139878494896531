@import '../../App.scss';

.logout-button-wrapper {
  display: flex;
  flex-direction: column;
}
.userDetails {
  color: $secondary-bg-colour;
  font-weight: bold;
  font-size: 0.6rem;
}

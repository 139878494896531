@import '../../../App.scss';
@import '../../../scssMixins.scss';

.display {
  @include display-text($danger, 1.7rem);
}

.editWrapper {
  button {
    margin-top: 0.5rem;
    background-color: $secondary-bg-colour;
    color: $main-font-colour;
    display: flex;
    align-items: baseline;
    opacity: 0.6;
    cursor: pointer;
  }
  button:hover {
    opacity: 1;
  }
}

.edit-modal-button-wrapper {
  display: flex;
  justify-content: space-between;
  button:first-child {
    background-color: $secondary-bg-colour;
    color: $main-font-colour;
    display: flex;
    align-items: baseline;
    opacity: 0.6;
    cursor: pointer;
    margin: 0.6rem 0;
  }
  button:hover {
    opacity: 1;
  }
  button:last-child {
    background-color: $danger;
    color: $main-font-colour;
  }
}

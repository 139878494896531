@import '../../App.scss';

.modal-wrapper {
  background-color: $main-font-colour;
  color: $main-bg-colour;
  border: 2px solid $burnt-orange;
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: MIN(100%, 600px);
  height: 80%;
  overflow-y: auto;
  padding: 0.5rem;
  animation-name: expand-modal;
  animation-duration: 1.2s;
  border-radius: 4px;
}
@keyframes expand-modal {
  from {
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
  }
  to {
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: MIN(100%, 600px);
    height: 80%;
  }
}
/* Modal styling END */
.modal-content-wrapper {
  height: auto;

  button.confirmation {
    background-color: $success !important;
    color: $main-bg-colour !important;
  }
}
.modal-header {
  /* border: 1px solid red; */
}
.modal-content {
  /* border: 1px solid yellow; */
}
.modal-footer {
  /* border: 1px solid red;
  background-color: $main-font-colour;
  position: sticky;
  bottom: 0;
  margin-top: auto;
  height: $footer-height; */
}

.modal-btn {
  display: flex;
  align-items: baseline;
  opacity: 0.6;
  cursor: pointer;
  margin: 0.6rem 0;
}
.modal-btn:hover {
  opacity: 1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(51, 51, 51, 0.85);
  cursor: pointer;
}

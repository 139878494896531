@import '../../../App.scss';

.clap-wrapper {
  display: flex;
  justify-content: space-between;
}
.text-small {
  font-size: 0.65rem;
  padding: 0 0.3rem;
}
.likes {
  cursor: pointer;
}
.dislikes {
  cursor: pointer;
}
.fa-thumbs-up:hover {
  color: greenyellow;
}
.fa-thumbs-down:hover {
  color: crimson;
}
.adminMessage {
  font-size: 0.6rem;
  color: $secondary-bg-colour;
  text-transform: uppercase;
  font-weight: bold;
}

.blog-content-wrapper {
  display: flex;
  justify-content: space-between;
}

.search {
  color: greenYellow;
  background-color: rgba(51, 51, 51, 1);
}

.searchWrapper {
  display: flex;
  justify-content: space-between;
  input[type='search'] {
    width: MIN(100%, 400px);
  }
  button {
    width: MIN(100%, 400px);
    margin-top: 0.2rem;
  }
  .blogNumber {
    font-size: 0.8rem;
    color: $secondary-bg-colour;
    font-weight: bold;
  }
}

@import '../../App.scss';

footer {
  background-color: $main-font-colour;
  color: $main-bg-colour;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  z-index: 100;
  margin-top: auto;
  height: $footer-height;
  padding: 0.2rem 0.2em;
  font-size: 0.6em;
}
footer button {
  font-size: 1.5em;
}
footer form button {
  font-size: 1em;
}
footer .modal-wrapper {
  background-color: $main-bg-colour;
}
footer .modal-wrapper form {
  font-size: 1.4em;
}

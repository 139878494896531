@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

/* variable setup */
$main-bg-colour: rgba(32, 32, 32, 1);
$secondary-bg-colour: rgba(255, 99, 0, 1);
$light-orange: rgba(236, 127, 55, 1);
$burnt-orange: rgba(190, 79, 12, 1);
$danger: crimson;
$success: rgb(111, 216, 111);
$main-font-colour: whitesmoke;
$border-colour: rgba(200, 200, 200, 0.5);
$header-height: 52px;
$footer-height: 40px;
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

/* Set core body defaults */
html {
  height: 100%;
}
body {
  font-family: 'Signika', sans-serif;
  font-family: 'Comfortaa', cursive;
  background-color: $main-bg-colour;
  color: $main-font-colour;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 16px;
  margin: 0;
  padding: 0.1em;
  overflow: hidden;
}
body p {
  font-size: 14px;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}
a:link {
  text-decoration: none;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
button {
  border-radius: 4px;
  border: 4px solid $border-colour;
  text-transform: uppercase;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  margin: 0.6rem 0;
}
button:hover {
  opacity: 1;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-decoration: underline;
  padding-bottom: 2px;
}

/* General global stye settings */

#app-wrapper {
  flex: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 100%;
  min-width: 100%;
}
section {
  /* max-width: 1290px; */
  width: 100%;
  margin: 0px auto;
  height: calc(100vh - #{$header-height} - #{$footer-height});
  overflow: auto;
}

footer {
  margin-top: auto;
}

p {
  line-height: 1.6;
  text-align: left;
}

h1,
h2,
h3 {
  display: inline-block;
}

/* General form styles */
form {
  background-image: linear-gradient(
    to right,
    rgba(100, 100, 100, 0.7),
    rgba(51, 51, 51, 0.1)
  );
  margin: 0 auto;
  border-radius: 1px;
  color: $main-font-colour;
  width: 100%;
  padding: 10px 20px;
}
form legend {
  font-size: 1.4em;
  margin-bottom: 10px;
}
form label {
  display: block;
  margin-bottom: 8px;
}
form input[type='text'],
form input[type='email'],
form input[type='password'],
input[type='search'],
form textarea {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-left: 4px solid $secondary-bg-colour;
  border-radius: 4px;
  font-size: 15px;
  margin: 0;
  outline: 0;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: #e8eeef;
  color: #8a97a0;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
}
form input[type='text'].entered {
  border-left: greenyellow 4px solid;
}
form input[type='email'].entered {
  border-left: greenyellow 4px solid;
}
form textarea.entered {
  border-left: greenyellow 4px solid;
}
form input[type='text'].invalid {
  border-left: $secondary-bg-colour 4px solid;
}
form input[type='email'].invalid {
  border-left: $secondary-bg-colour 4px solid;
}
form input[type='password'].entered {
  border-left: greenyellow 4px solid;
}
form input[type='password'].invalid {
  border-left: $secondary-bg-colour 4px solid;
}
input[type='search'].entered {
  border-left: greenyellow 4px solid;
}
input[type='search'].invalid {
  border-left: $secondary-bg-colour 4px solid;
}
form textarea.invalid {
  border-left: $secondary-bg-colour 4px solid;
  min-height: 250px;
}

form textarea {
  min-height: 250px;
}
input[type='checkbox'] {
  /* change "blue" browser chrome to yellow */
  filter: invert(100%) hue-rotate(53deg) brightness(1.3);
  width: 18px;
  height: 20px;
  margin-left: 0.5rem;
  position: relative;
  top: 5px;
}

/* Fieldset for all the forms */

fieldset.fieldSet {
  border: 1px solid rgba(100, 100, 100, 0.7);
}
/* SCROLL-BAR CUSTOM STYLING */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: $main-bg-colour;
}
::-webkit-scrollbar-thumb {
  background: $main-font-colour;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: $border-colour;
}
/* SCROLL-BAR CUSTOM STYLING */
hr {
  border: 0;
  height: 4px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}
/* Home page blog view images */
.img-fit {
  max-height: 30%;
}

/* General helper classes */
.border-temp {
  border: 4px solid yellowgreen;
}
